import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PortableTextContent from '../components/portableTextContent'

const ContactPage = ({ data }) => {
  const { sanityPage: contactPageContent } = data

  return (
    <Layout
      headerImage={contactPageContent.headerImage.asset}
      headerText={contactPageContent.title}
    >
      <section>
        <PortableTextContent rawContent={contactPageContent._rawBody} />
      </section>
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default ContactPage

export const ContactPageQuery = graphql`
  query ContactPage($id: String!) {
    sanityPage(id: { eq: $id }) {
      id
      title
      _rawBody
      headerImage {
        asset {
          fluid(maxWidth: 1600) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`
